import React, { Suspense, lazy } from "react";
import { MuiThemeProvider } from "@material-ui/core";
import { Route, HashRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import { theme } from "./themes/theme";
import { UserProvider } from "./context/UserContext";
import UniversalLoader from "./components/UniversalLoader";
const Home = lazy(() => import("./pages/home/Home"));
const ErrorPage = lazy(() => import("./pages/error/ErrorPage"));
const Confirmation = lazy(() => import("./pages/Confirmation"));
const FormConfirmation = lazy(() =>
  import("./pages/formconfirmation/FormConfirmation")
);
const Form = lazy(() => import("./pages/form/Form"));

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <UserProvider>
          <HashRouter>
            <Suspense fallback={<UniversalLoader />}>
              <Route exact path="/" component={Home} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/error" component={ErrorPage} />
              <Route exact path="/confirmation" component={Confirmation} />
              <Route exact path="/form" component={Form} />
              <Route
                exact
                path="/formconfirmation"
                component={FormConfirmation}
              />
            </Suspense>
          </HashRouter>
        </UserProvider>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}
export default App;
