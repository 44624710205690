import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LoaderContainer } from "./styledComponents";

const UniversalLoader = () => {
  return (
    <LoaderContainer>
      <CircularProgress />
    </LoaderContainer>
  );
};
export default UniversalLoader;
